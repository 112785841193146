import { createFeatureSelector, createSelector } from '@ngrx/store';
import { get } from 'lodash';
import { AptBillType } from '../../common/enums/apttus/apt-bill-type';
import { AptCommodityType } from '../../common/enums/apttus/apt-commodity-typeof-sale';
import { AptLineStatus } from '../../common/enums/apttus/apt-line-status';
import { productsToAddresses } from '../../common/functions/address.functions';
import {
    findProductToShowResidentialAddress,
    productsToAdressTypesVisibility,
} from '../../common/functions/misc.functions';
import {
    d365AccountMigratedToMastershipType,
    flowTypeToAptSalesProcess,
    flowTypeToMacroFlowType,
} from '../../common/functions/remap.functions';
import {
    containsProductComplex,
    containsProductInsurance,
    containsProductMaintenance,
    containsProductSmartHome,
    flowTypeUtil,
    hasOnlyExtraCommodities,
    isCommodityFamily,
} from '../../common/functions/verifications.functions';
import { MacroFlowType } from '../models/flow-type';
import { OrderEntryState_v2, Product } from '../models/order-entry-state_v2';
import { TargetProductIndex } from '../models/utility.types';
import { selectFornitureAttive } from './order-entry.selectors';
import {
    isAssetCommodityProduct,
    isCommodityProduct,
    isGasProduct,
    isPendingAssetCommodityProduct,
    isPowerProduct,
    productFieldSelectorGenerator,
    wrapperSelectProductByIndex,
} from './selector-utility.functions';
import { selectCartSegment, selectContactLead, selectUserState } from './user.selectors';

type ObjectPath<
    O extends Object,
    K extends keyof O & string = keyof O extends string ? keyof O : never,
> = K extends string ? K | `${K}.${ObjectPath<O[K]>}` : never;

export const v2OrderEntryState = createFeatureSelector<OrderEntryState_v2>('orderEntry');
// FlowType
export const v2SelectFlowType = createSelector(v2OrderEntryState, (state) => state?.flowType);

export const v2SelectMacroFlowType = createSelector(v2OrderEntryState, (state) =>
    flowTypeToMacroFlowType(state?.flowType),
);
export const v2SelectSalesProcess = createSelector(v2OrderEntryState, (state) =>
    flowTypeToAptSalesProcess(state?.flowType),
);

/**
 * @description Lista prodotti NON FILTRATA PER ItemId
 */
export const v2SelectAllProducts = createSelector(v2OrderEntryState, (state) => state.products);

/**
 * @description Estrae l'elenco dei prodotti visibili per l'utente (senza prodotti o asset tecnici) - AUTOFILTRATA per itemId o productIdx
 */
export const v2SelectVisibleProducts = (productIdx?: TargetProductIndex) =>
    createSelector(v2OrderEntryState, (state) =>
        Array.from(
            productFieldSelectorGenerator({
                state,
                productSelector: (product) => product,
                productIdx,
            }),
        ),
    );

/**
 * @description Lista prodotti NON FILTRATA - AUTOFILTRATA per itemId o productIdx
 */
export const v2SelectAssetIds = (productIdx?: TargetProductIndex) =>
    createSelector(v2SelectVisibleProducts(productIdx), (products) => products.map((prod) => prod.assetId));

/**
 * @description Lista prodotti di tipo Commodity - AUTOFILTRATA per itemId o productIdx
 */
export const v2SelectCommodityProducts = (productIdx?: TargetProductIndex) =>
    createSelector(v2SelectVisibleProducts(productIdx), (products) =>
        products.filter((prod) => isCommodityFamily(prod?.family)),
    );

/**
 * @description Prendo i prodotti Commodity che hanno LineItemStatus === "Cancelled" o undefined - NON FILTRATA PER ItemId
 */
export const v2SelectCancelledOrUndefinedLineitemStatusProduct = createSelector(v2SelectAllProducts, (products) =>
    products.filter((prod) => !prod.lineItemStatus || prod.lineItemStatus === AptLineStatus.Cancelled),
);

/**
 * @description Estrae l'elenco dei prodotti con 'lineItemStatus' diverso da Cancelled
 */
export const v2SelectNotCancelledProducts = createSelector(v2OrderEntryState, (state) =>
    state.products.filter((prod) => prod?.lineItemStatus !== AptLineStatus.Cancelled),
);

/**
 * @description Estrae l'elenco dei prodotti visibili di tipo commodity unici per pod/pdr e li filtra per il tipo se fornito
 */
export const v2SelectDistinctCommodities = (commodityType?: AptCommodityType | 'ALL') =>
    createSelector(v2SelectCommodityProducts(commodityType || 'ALL'), (products) =>
        products.filter(
            ({ podPdr, powerOrGas }, idx, prodList) =>
                !podPdr ||
                !prodList
                    .slice(idx + 1)
                    .some((product) => product?.podPdr === podPdr && product?.powerOrGas === powerOrGas),
        ),
    );

/**
 * @description Verifica la presenza di almeno una commodity POWER ed una GAS - INDIPENDENTE DA ItemId
 */
export const v2HasPowerAndGas = createSelector(v2SelectCommodityProducts('ALL'), (products) =>
    Object.values(AptCommodityType).every((commodityType) =>
        products.some(({ powerOrGas }) => powerOrGas === commodityType),
    ),
);

export const v2SelectActiveVisibleMainNotCommodityProducts = createSelector(
    v2SelectVisibleProducts('ALL'),
    (products) => products.filter((prod) => !isCommodityFamily(prod?.family)),
);

export const v2SelectPendingAssetCommodities = (commodityType?: AptCommodityType) =>
    createSelector(v2SelectCommodityProducts(commodityType || 'ALL'), (products) =>
        (products || []).filter(isPendingAssetCommodityProduct(commodityType)),
    );

/**
 * @description Prodotti attivi, visibili, con assetId e del commodityType fornito - FILTRATO PER commodityType o 'ALL' (default)
 */
export const v2SelectCommoditiesByFilter = (
    commodityType?: AptCommodityType,
    filters?: { lineItemdId?: boolean; assetId?: boolean; sourceAssetId?: boolean },
) => {
    const PARAM_FIELD_MAP: {
        [key in keyof typeof filters]: ObjectPath<Product>;
    } = {
        lineItemdId: 'lineItemId',
        assetId: 'assetId',
        sourceAssetId: 'sourceCustomer.assetId',
    };
    return createSelector(
        v2SelectAllProducts,
        (products) =>
            (products || [])
                .map((product, idx) => ({
                    ...product,
                    idx,
                }))
                .filter(
                    (product) =>
                        isCommodityProduct(commodityType)(product) &&
                        Object.entries(PARAM_FIELD_MAP).every(
                            ([filter, productField]) =>
                                // Utilizzo "get" di lodash perchè "productField" può contenere dei path (tipo sourceCustomer.assetId) e agevola il recupero delle informazioni negli oggetti innestati
                                typeof filters?.[filter] !== 'boolean' ||
                                !!get(product, productField) === filters?.[filter],
                        ),
                ) as (Product & { idx: number })[],
    );
};

export const v2SelectExtracommoditiesByFilter = (filters?: {
    lineItemdId?: boolean;
    assetId?: boolean;
    sourceAssetId?: boolean;
}) => {
    const PARAM_FIELD_MAP: {
        [key in keyof typeof filters]: ObjectPath<Product>;
    } = {
        lineItemdId: 'lineItemId',
    };
    return createSelector(v2SelectAllProducts, (products) =>
        (products || []).filter(
            (product) =>
                hasOnlyExtraCommodities([product]) &&
                Object.entries(PARAM_FIELD_MAP).every(
                    ([filter, productField]) =>
                        // Utilizzo "get" di lodash perchè "productField" può contenere dei path (tipo sourceCustomer.assetId) e agevola il recupero delle informazioni negli oggetti innestati
                        typeof filters?.[filter] !== 'boolean' || !!get(product, productField) === filters?.[filter],
                ),
        ),
    );
};

/**
 * @description preloaded products from assets to be filled - Voltura, CambioProdotto
 */
export const v2HasPendingProductsFromAsset = createSelector(v2SelectCommodityProducts('ALL'), (products) =>
    (products || []).some(isPendingAssetCommodityProduct()),
);

export const v2HasProductsFromAsset = createSelector(v2SelectAllProducts, (products) =>
    (products || []).some((product) => isAssetCommodityProduct()(product)),
);

export const v2SelectCurrentProduct = wrapperSelectProductByIndex((product) => product);

export const v2IsCurrentProductFromAsset = (commodityType?: AptCommodityType, productIdx?: TargetProductIndex) =>
    createSelector(v2SelectCurrentProduct(productIdx), (product) => isAssetCommodityProduct(commodityType)(product));

export const v2SelectMastership = createSelector(
    [v2SelectCommodityProducts('ALL'), selectUserState],
    (products, userState) => ({
        sourceCustomerMastership: products.find((product) => !!product.sourceCustomer?.mastership)?.sourceCustomer
            ?.mastership,
        customerMastership:
            userState?.customerMastership ||
            d365AccountMigratedToMastershipType(userState?.customerInfo?.EglMigration) ||
            d365AccountMigratedToMastershipType(userState?.contact?.EglMigration),
    }),
);
export const v2SelectAppointment = createSelector(v2OrderEntryState, (state) => state?.appointment);

export const v2SelectInvoiceShippingMethod = wrapperSelectProductByIndex(
    (product) => product?.configurations?.invoiceShippingMethod,
);

export const v2SelectScontoCasaLavoro = wrapperSelectProductByIndex(
    (product) => product?.configurations?.scontoCasaLavoro,
);

export const v2SelectCasaLavoroCartId = createSelector(v2OrderEntryState, (state) => state?.casaLavoroCartId);

export const v2SelectCasaLavoroFilter = createSelector(v2OrderEntryState, (state) => state?.casaLavoroFilter);

export const v2SelectCommunicationMethodAddress = (productIdx?: TargetProductIndex) =>
    createSelector(
        selectContactLead,
        v2SelectCommunicationAddress(productIdx),
        v2SelectInvoiceShippingMethod(productIdx),
        (contactLead, communicationAddress, ism) => ({
            type: ism,
            address:
                ism === AptBillType.Digitale ? contactLead?.contact?.emailaddress1 : communicationAddress?.fullAddress,
        }),
    );

/**
 * @description Verifica la presenza dei costi per i prodotti attivi e visibili - INDIPENDENTE DA ItemId
 */
export const v2HasCosts = createSelector(v2SelectVisibleProducts('ALL'), (products) =>
    products.some(
        (product) => product?.prices && Object.values(product?.prices).some((value) => typeof value === 'number'),
    ),
);

export const v2SelectCommunicationAddress = wrapperSelectProductByIndex((product) => product?.communicationAddress);

export const v2CanShowCommunicationAddress = createSelector(v2OrderEntryState, (state) => {
    const currentProduct = productFieldSelectorGenerator({ state, productSelector: (product) => product }).next().value;
    const firstAvailableProduct = productFieldSelectorGenerator({
        state,
        productSelector: (product) => product,
        productIdx: 0,
    }).next().value;
    return currentProduct === firstAvailableProduct;
});

export const v2SelectEffectiveDate = wrapperSelectProductByIndex((product) => product.effectiveDate);

export const v2SelectActDate = wrapperSelectProductByIndex((product) => product.actDate);

/**
 * @description Recupero la effective date più vecchia tra quelle dei prodotti - INDIPENDENTE DA ItemId
 */
export const v2SelectMinEffectiveDate = createSelector(v2SelectVisibleProducts('ALL'), (products) =>
    products.reduce(
        (minDate, { effectiveDate }) =>
            effectiveDate && minDate && effectiveDate < minDate ? effectiveDate : minDate || effectiveDate,
        null as Date,
    ),
);

export const v2SelectOriginalStartDate = wrapperSelectProductByIndex((product) => product.originalStartDate);

export const v2SelectStartDate = wrapperSelectProductByIndex((product) => product.startDate);

export const v2SelectFirma = createSelector(v2OrderEntryState, (state) => state?.firma);

export const v2SelectTicketNumber = createSelector(v2OrderEntryState, (state) => state?.incident?.ticketNumber);

export const v2SelectPrices = wrapperSelectProductByIndex((product) => product.prices);

export const v2SelectTechnicalDetails = wrapperSelectProductByIndex((product) => product.technicalDetails);

export const v2SelectVendor = wrapperSelectProductByIndex((product) => product.vendor);

export const v2SelectPartNumber = wrapperSelectProductByIndex((product) => product?.partNumber);

export const V2SelectDefaultNavigationParams = createSelector(
    v2SelectFlowType,
    v2SelectCommodityProducts('ALL'),
    (flowType, commodities) => {
        const commoditiesTypes = Array.from(new Set(commodities.map((comm) => comm.powerOrGas)));
        const COMMODITY_TYPE_TO_SUBCAT = {
            [AptCommodityType.Gas]: 'GAS',
            [AptCommodityType.Power]: 'LUCE',
            DEFAULT: '',
        };

        return {
            flowType,
            categoryName: commoditiesTypes.length ? '001-GAS E LUCE' : '',
            subCategoryName: COMMODITY_TYPE_TO_SUBCAT[commoditiesTypes.length === 1 ? commoditiesTypes[0] : 'DEFAULT'],
        };
    },
);

export const v2SelectPaymentInfo = wrapperSelectProductByIndex((product) => product.paymentInfo);
export const v2SelectPaymentInstrument = createSelector(
    v2SelectVisibleProducts(),
    (products) => (products || [])[0]?.paymentInfo?.paymentInstrument,
);
export const v2SelectConvention = createSelector(v2OrderEntryState, (state) => state?.convention);
export const v2SelectTaxDeduction = createSelector(v2OrderEntryState, (state) => state?.taxDeduction);
export const v2SelectCreditAssignment = createSelector(v2OrderEntryState, (state) => state?.creditAssignment);
export const v2HasResidentialAddressVisible = createSelector(
    v2OrderEntryState,
    selectCartSegment,
    (state, cartSegment) => {
        const currentProduct = findProductToShowResidentialAddress({
            products: Array.from(
                productFieldSelectorGenerator({
                    state,
                    productSelector: (product) => product,
                }),
            ),
            cartSegment,
        });
        const targetProduct = findProductToShowResidentialAddress({
            products: Array.from(
                productFieldSelectorGenerator({
                    state,
                    productSelector: (product) => product,
                    productIdx: 'ALL',
                }),
            ),
            cartSegment,
        });
        return !!currentProduct && currentProduct === targetProduct;
    },
);

export const v2SelectSendCommunications = createSelector(
    v2OrderEntryState,
    (state) => state?.sendCommunications ?? true,
);

export const v2SelectProductFamilies = createSelector(v2OrderEntryState, (state) =>
    Array.from(
        new Set(
            productFieldSelectorGenerator({
                state,
                productSelector: (product) => product.family,
            }),
        ),
    ),
);
export const v2SelectMainAddress = createSelector(v2OrderEntryState, (state) => state?.contact?.mainAddress);
export const v2SelectDomicileAddress = createSelector(v2OrderEntryState, (state) => state?.contact?.domicileAddress);
export const v2SelectElectronicInvoicing = createSelector(v2OrderEntryState, (state) => state?.fatturazioneElettronica);
export const v2SelectAnagraficaMb = createSelector(v2OrderEntryState, (state) => state?.anagraficaMb);
export const v2SelectIsWinBack = wrapperSelectProductByIndex((product) => product?.isWinBack);
export const v2SelectFornitureAttive = createSelector(v2OrderEntryState, (state) => state?.fornitureEsistenti);

export const v2SelectNewIndirizziData = (enableDomicile?: boolean, productIdx?: TargetProductIndex) =>
    createSelector(
        v2SelectVisibleProducts(productIdx),
        v2CanShowCommunicationAddress,
        v2SelectMainAddress,
        v2SelectDomicileAddress,
        selectCartSegment,
        (products, showCommunicationAddress, mainAddress, domicileAddress, segment) => {
            const addressVisibility = productsToAdressTypesVisibility(products, segment);
            return {
                //determino la visibilità delle sezioni dei tipi d'indirizzo
                addressVisibility: {
                    ...addressVisibility,
                    FATTURAZIONE: addressVisibility.FATTURAZIONE && showCommunicationAddress,
                    DOMICILIO: enableDomicile ? addressVisibility.DOMICILIO : false,
                    ABITAZIONE_ABITUALE: enableDomicile ? addressVisibility.ABITAZIONE_ABITUALE : false,
                    ABITAZIONE_DI_PROPRIETA: enableDomicile ? addressVisibility.ABITAZIONE_DI_PROPRIETA : false,
                },
                // showCommunicationAddress,
                mainAddress,
                domicileAddress,
                ...productsToAddresses(products),
            };
        },
    );

export const v2isScoreCardRequired = createSelector(
    v2OrderEntryState,
    ({ creditCheckStatus }) => creditCheckStatus.callScorecard,
    // MAI per gli Amministrativi, Sì per i VIP e i Commerciali
    // NB: Il VIP non blocca
);
export const v2SelectContact = createSelector(v2OrderEntryState, (state) => state?.contact);

export const v2SelectRevocationReason = wrapperSelectProductByIndex(
    (product) => product?.paymentInfo?.paymentTool?.revocationReason,
);

export const v2SelectEmailConfirmed = createSelector(v2OrderEntryState, (state) => !!state?.contact?.emailConfirmed);
export const v2SelectIsCustomerRegistered = createSelector(v2OrderEntryState, (state) => state?.contact?.isRegistered);

export const v2SelectWinbackCommodityTypes = createSelector(
    v2SelectDistinctCommodities(AptCommodityType.Power),
    v2SelectDistinctCommodities(AptCommodityType.Gas),
    v2SelectFlowType,
    selectFornitureAttive,
    (powerProducts, gasProducts, flowType, fornitureAttive) => {
        const isFlowTypeEnabled = flowTypeUtil(flowType).inMacroFlowTypes(MacroFlowType.SwitchIn);

        const availableCommodityTypeMap = {
            [AptCommodityType.Gas]: !!gasProducts?.length,
            [AptCommodityType.Power]: !!powerProducts?.length,
        };

        const winbackCommodityTypes: AptCommodityType[] = [];

        return isFlowTypeEnabled
            ? (fornitureAttive || []).reduce(
                  (aggr, { isWinBack, powerOrGas }) =>
                      !aggr.includes(powerOrGas) && isWinBack && availableCommodityTypeMap[powerOrGas]
                          ? aggr.concat(powerOrGas)
                          : aggr,
                  winbackCommodityTypes,
              )
            : winbackCommodityTypes;
    },
);
export const v2SelectInformationCosts = createSelector(v2OrderEntryState, (state) => {
    const informationCosts = productFieldSelectorGenerator({
        state,
        productSelector: (product) => product?.prices?.informationCosts,
        productIdx: 0,
    }).next().value;
    return informationCosts;
});

export const v2SelectInsuranceProducts = (productIdx?: TargetProductIndex) =>
    createSelector(v2SelectVisibleProducts(productIdx), (products) =>
        products.filter((prod) => containsProductInsurance(prod?.productType)),
    );

export const v2SelectSubProductType = () =>
    createSelector(v2SelectCurrentProduct(), (product) => product?.subProductType);

export const v2SelectRemoteReadStatus = () =>
    createSelector(v2SelectCurrentProduct(), (product) => product.meterRemoteRead);

export const selectNextMaintenanceIdx = createSelector(
    v2SelectAllProducts,
    v2SelectCurrentProduct(),
    (products, currentProduct): number => {
        const currentIdx =
            containsProductMaintenance(currentProduct?.productType) ||
            containsProductComplex(currentProduct?.productType)
                ? currentProduct.idx
                : -1;
        const ids = products
            .filter((prod) => containsProductMaintenance(prod.productType) || containsProductComplex(prod.productType))
            .map((p) => p.idx);
        return ids[ids.indexOf(currentIdx) + 1];
    },
);

export const selectMaintenanceProducts = createSelector(v2SelectVisibleProducts(), (products) =>
    (products || []).filter((p) => containsProductMaintenance(p.productType)),
);
export const selectSmartHomeProducts = createSelector(v2SelectVisibleProducts(), (products) =>
    (products || []).filter((p) => containsProductSmartHome([p.productType])),
);
export const v2SelectInstantAcceptanceFlag = createSelector(v2OrderEntryState, (state) => state?.isInstantAcceptance);

/**
 * @description Verifica la presenza di almeno una commodity POWER o una GAS - INDIPENDENTE DA ItemId
 */
export const v2HasPowerOrGas = createSelector(v2SelectCommodityProducts('ALL'), (products) => {
    let hasPowerOrGas = false;

    products.forEach((product) => {
        hasPowerOrGas = isPowerProduct(product) || isGasProduct(product);
        return;
    });

    return hasPowerOrGas;
});

export const v2SelectAutofill = createSelector(v2OrderEntryState, (state) =>
    state?.products?.filter((p) => p.checkAutofill == 'KO'),
);

export const v2SelectTransferModel = createSelector(v2OrderEntryState, (state) => state?.transferModel);
export const v2SelectAdoptPanel = (productId) =>
    createSelector(
        v2OrderEntryState,
        (state) => state?.products?.find((p) => p.productId == productId)?.configurations?.adoptPanel,
    );
