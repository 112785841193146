import { Injectable } from '@angular/core';
import { get } from 'lodash';
import { FlowType, MacroFlowType } from '../../../store/models/flow-type';
import { flowTypeUtil } from '../../functions/verifications.functions';
import { ToggleFeature } from '../../models/app/app-config';
import { PrivateConfigurationService } from './private-configuration.service';

@Injectable({
    providedIn: 'root',
})
export class FeatureToggleService {
    config: ToggleFeature;
    constructor(private configSrv: PrivateConfigurationService) {
        this.configSrv.configObservable$.subscribe((config) => {
            this.config = config.toggleFeature;
        });
    }

    isFlowTypeEnabled(f: FlowType): boolean {
        return this.isKeyEnabled((Object.entries(FLOW_TYPE_TOGGLE_MAP).find(([, matchFunc]) => matchFunc(f)) || [])[0]);
    }

    get(key: string[] | string, defaultValue?: any): any {
        return get(this.config, key, defaultValue);
    }

    get isSwitchInE2EEnabled(): boolean {
        return this.isKeyEnabled(this.config?.switch_e2e);
    }

    get orderEntryStateV2Enabled(): boolean {
        return this.isKeyEnabled(this.config?.switch_e2e);
    }

    get isVolturaSwitchEnabled(): boolean {
        return this.isKeyEnabled(this.config?.volturaSwitch_e2e);
    }

    get isVolturaSempliceEnabled(): boolean {
        return this.isKeyEnabled(this.config?.voltura);
    }

    get isActivationEnabled(): boolean {
        return this.isKeyEnabled(this.config?.attivazione);
    }

    get isComplexActivationEnabled(): boolean {
        return this.isKeyEnabled(this.config?.attivazione_complessa);
    }

    get isTerminationEnabled(): boolean {
        return this.isKeyEnabled(this.config?.cessazione);
    }
    get isAdministrativeTerminationEnabled(): boolean {
        return this.isKeyEnabled(this.config?.cessazioneAmministrativa);
    }

    get isVariazioneCommercialeEnabled(): boolean {
        return this.isKeyEnabled(this.config?.variazioneCommerciale);
    }

    get isScontoStandaloneEnabled(): boolean {
        return this.isKeyEnabled(this.configSrv.config.toggleFeature?.scontoStandalone);
    }

    get isCombinedSaleEnabled(): boolean {
        return this.isKeyEnabled(this.config?.combinedSale);
    }

    get isDomiciliazioneEnabled(): boolean {
        return this.isKeyEnabled(this.config?.domiciliazione);
    }

    get isVariazioneTecnicaLavoriEnabled(): boolean {
        return this.isKeyEnabled(this.configSrv.config.toggleFeature?.variazioneTecnicaLavori);
    }

    get isVariazioneTecnicaVerificheEnabled(): boolean {
        return this.isKeyEnabled(this.configSrv.config.toggleFeature?.variazioneTecnicaVerifiche);
    }

    get isCouponEnabled(): boolean {
        return this.isKeyEnabled(this.config?.coupon);
    }

    get isScriptCartDataFieldEnabled(): boolean {
        return this.isKeyEnabled(this.config?.scriptCartDataField);
    }

    get isVariazioniTecniche2Enabled(): boolean {
        return this.isKeyEnabled(this.configSrv.config.toggleFeature?.variazioniTecniche2);
    }

    get isVariazioniTecniche3Enabled(): boolean {
        return this.isKeyEnabled(this.configSrv.config.toggleFeature?.variazioniTecniche3);
    }

    get isConventionEnabled(): boolean {
        return this.isKeyEnabled(this.configSrv.config.toggleFeature?.convenzioni);
    }

    get isRolloutEnabled(): boolean {
        return this.isKeyEnabled(this.configSrv.config.toggleFeature?.rolloutEnabled);
    }

    get isCambioTipologiaFornituraEnabled(): boolean {
        return this.isKeyEnabled(this.configSrv.config.toggleFeature?.cambioTipologiaFornitura);
    }

    get isAboPricingModeEnabled(): boolean {
        return this.isKeyEnabled(this.configSrv.config.toggleFeature?.aboPricingMode);
    }

    get isCheckInsurancePODPDREnabled(): boolean {
        return this.isKeyEnabled(this.configSrv.config.toggleFeature?.checkInsurancePODPDR);
    }

    get isCheckInsuranceTaxCodeEnabled(): boolean {
        return this.isKeyEnabled(this.configSrv.config.toggleFeature?.checkInsuranceTaxCode);
    }

    get isExtraCommodityFlowEnabled(): boolean {
        return this.isKeyEnabled(this.configSrv.config.toggleFeature?.extraCommodityFlow);
    }

    get isPltLoaderEnabled(): boolean {
        return this.isKeyEnabled(this.configSrv.config.toggleFeature?.pltLoader);
    }

    get isCheckBalanceEnabled(): boolean {
        return this.isKeyEnabled(this.configSrv.config.toggleFeature?.checkBalance);
    }

    get isPolizze2023Enabled(): boolean {
        return this.isKeyEnabled(this.configSrv.config.toggleFeature?.polizze2023);
    }

    get areTaxDeductionsEnabled(): boolean {
        return this.isKeyEnabled(this.configSrv.config.toggleFeature?.taxDeductions);
    }

    get isPriceListAmmEnabled(): boolean {
        return this.isKeyEnabled(this.configSrv.config.toggleFeature?.priceListAmm);
    }

    get isEffectiveDateMessageEnabled(): boolean {
        return this.isKeyEnabled(this.config?.showEffectiveDateMessage);
    }

    get isScontoMultiMicroEnabled(): boolean {
        return this.isKeyEnabled(this.configSrv.config.toggleFeature?.scontoMultiMicroBusiness);
    }

    get isScontoCasaLavoroEnabled(): boolean {
        return this.isKeyEnabled(this.configSrv.config.toggleFeature?.scontoCasaLavoro);
    }

    get isCartConventionsEnabled(): boolean {
        return this.isKeyEnabled(this.configSrv.config.toggleFeature?.cartConventionsEnabled);
    }

    get isReadOnlyOnDefaultPAVEnabled(): boolean {
        return this.isKeyEnabled(this.configSrv.config.toggleFeature?.readOnlyOnDefaultPAVEnabled);
    }

    get isCPDateMessageEnabled(): boolean {
        return this.isKeyEnabled(this.configSrv.config.toggleFeature?.cpDateMessageEnabled);
    }

    get isSwCustomerProtectionEnable(): boolean {
        return this.isKeyEnabled(this.configSrv.config.toggleFeature?.swCustomerProtection);
    }

    get isCheckFieldVolturaEnable(): boolean {
        return this.isKeyEnabled(this.configSrv.config.toggleFeature?.checkFieldVoltura);
    }

    private isKeyEnabled(key: string | boolean) {
        if (typeof key === 'string') {
            return !(this.configSrv.get(key) === false);
        } else {
            return !(key === false);
        }
    }
}

type FlowTypePrivateConfig = {
    [kay: string]: (f: FlowType) => boolean;
};

const FLOW_TYPE_TOGGLE_MAP: FlowTypePrivateConfig = {
    'toggleFeature.switchin': (flowType) => flowTypeUtil(flowType).inMacroFlowTypes(MacroFlowType.SwitchIn),
    'toggleFeature.volturaSwitch': (flowType) => flowTypeUtil(flowType).inMacroFlowTypes(MacroFlowType.VolturaSwitchIn),
    'toggleFeature.voltura': (flowType) => flowTypeUtil(flowType).inMacroFlowTypes(MacroFlowType.Voltura),
    'toggleFeature.attivazione': (flowType) => flowTypeUtil(flowType).inMacroFlowTypes(MacroFlowType.Attivazione),
    'toggleFeature.cambioProdotto': (flowType) => flowTypeUtil(flowType).inMacroFlowTypes(MacroFlowType.CambioProdotto),
};
